<template>
  <div class="cert-traning-manage-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">
          <div>数据导入/导出</div>
          <el-button
            style="width:80px;height:25px;margin-left:10px;margin-top: 15px;"
            @click="onBack"
            >返回</el-button
          >
        </span>
      </div>
      <div class="card-content">
        <el-tabs v-model="activeTab" tab-position="left">
          <el-tab-pane label="导入成绩" name="1">
            <div class="title">
              <span>导入成绩</span>
            </div>
            <div class="tab-main">
              <div>1.选择导入考试人员所考的证书，并下载模板</div>

              <el-select
                v-model="form.certId"
                placeholder="请选择证书类型"
                @change="categoryChange"
              >
                <el-option
                  v-for="(item, index) in categoryList"
                  :key="index"
                  :value="item.certId"
                  :label="item.certName"
                >
                </el-option>
              </el-select>

              <el-radio-group
                v-for="(level_item, level_index) in levelArrays"
                :key="level_index"
                v-model="form.certLevel"
              >
                <el-radio style="margin-right: 20px;" :label="level_item">{{
                  certLevelShowArrays[level_index]
                }}</el-radio>
              </el-radio-group>

              <el-button type="primary" style="margin: 20px 0px;" @click="onDownloadGetClassStudy"
                >下载导入模板</el-button
              >
              <div>2.上传考试成绩文件</div>

              <div class="upload-file">
                <el-upload
                  class="uploader-file"
                  action=""
                  :file-list="fileList"
                  drag
                  :limit="1"
                  multiple
                  :http-request="uploadRequest"
                  :on-remove="handleRemove"
                  :on-preview="handlePreview"
                  :accept="fileType"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <p class="tip" style="line-height:6px">支持扩展名：{{ fileType }}</p>
                </el-upload>
              </div>

              <div class="titL">
                单个文件大小5MB，支持扩展名：.png .jpg.jpeg（最多可上传5个文件）
              </div>
              <el-button type="primary" style="margin: 20px 0px;" @click="onUpload"
                >确定上传</el-button
              >
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="添加课程" name="2">
            <div class="title">
              <span>添加培训课程</span>
            </div>
            <div class="tab-main">
              <div>22222</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置题库" name="3">
            <div class="title">
              <span>配置题库</span>
            </div>
            <div class="tab-main">
              <div>333333</div>
            </div>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
import to from 'await-to'
import { certificateList, downloadTempate, uploadCertExcel } from '@/api/certCenter'
export default {
  // name: 'certMangerAdd',
  components: {},
  data() {
    return {
      activeTab: '1',
      categoryList: [],

      levelArrays: [],
      certLevelShowArrays: [],
      form: {
        certId: '',
        certLevel: '0',
      },
      file: '',
      fileType: '.xlsx,.xls',
    }
  },
  mounted() {
    this.onCertificateList()
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    async onCertificateList() {
      const [res, err] = await to(certificateList({ current: 1, size: 100000, publishStatus: 2 }))
      if (err) return this.$message.warning(err.msg)
      this.categoryList = res.data.list
    },
    categoryChange(e) {
      this.form.certLevel = '0'
      this.levelArrays = []
      console.log(e)
      for (let i = 0; i < this.categoryList.length; i++) {
        let item = this.categoryList[i]
        if (item.certId == e) {
          console.log(item)
          if (item.levelArrays) {
            this.levelArrays = item.levelArrays
            this.certLevelShowArrays = item.certLevelShow.split(',')
            console.log(this.certLevelShowArrays)
            console.log(this.levelArrays)
          }
        }
      }
    },
    async onDownloadGetClassStudy() {
      const [res, err] = await to(
        downloadTempate({
          certPrivateId: this.form.certId,
          level: parseInt(this.form.certLevel),
        }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '导入模版.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },

    uploadRequest(file) {
      console.log(file)
      this.file = file
    },

    async onUpload() {
      let file = this.file
      const params = new FormData()
      params.append('fileTypeCode', 'F002')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(uploadCertExcel(params))
      if (err) {
        // this.$message.error(err.msg)
        let confirmText = err.msg
        const newDatas = []
        const h = this.$createElement
        for (const i in confirmText) {
          newDatas.push(h('p', null, confirmText[i]))
        }
        this.$confirm('上传失败', {
          title: '上传失败',
          message: h('div', null, newDatas),
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
          showClose: false,
          center: true,
        })
        return
      }
      this.$message.success('上传成功')
      console.log(res)
      this.onBack()
    },

    // handleRemove(file, fileList) {
    //   this.fileList = fileList
    // },
  },
}
</script>
<style lang="scss" scoped>
.cert-traning-manage-add {
  ::v-deep.el-tabs {
    .el-tabs__item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 60px;
      color: #c9c9c9;
      text-align: center;
      border-right: 2px solid #f5f5f5;
      &.is-active {
        color: #ff7b33;
        background: #fcfbfa;
      }
    }
  }
  ::v-deep.el-card {
    .el-card__body {
      padding: 0;
    }
    .card-content {
      height: calc(100vh - 210px);
      .title {
        height: 78px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        font-weight: 500;
        line-height: 78px;
        color: #4d4e5d;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  ::v-deep.el-tabs {
    height: 100%;
    .el-tabs__header {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #e2e2e2;
      margin-right: 0;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .tab-main {
      height: calc(100vh - 290px);
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
      .el-select {
        width: 350px;
        margin-right: 20px;
      }
      .upload-file {
        margin-top: 20px;
        ::v-deep.el-upload-list {
          width: 360px;
        }
      }
      .titL {
        margin-top: 20px;
        height: 14px;
        font-size: 12px;
        font-family: SourceHanSansCN-Normal-, SourceHanSansCN-Normal;
        font-weight: normal;
        color: #909399;
        line-height: 11px;
      }
    }
  }
}
</style>
